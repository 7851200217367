export const TEST_MODELS_COMPONENT_DATA = [
  {
    id: '105',
    meshData: [
      {
        id: 1,
        position: [-0.52, 0.3, -350.8],
        rotation: [-1.559, -0.488, 0.005],
        size: [0.4, 2.6, 0.3],
        description: 'Antenna Object 1',
      },
      {
        id: 2,
        position: [-0.52, 0.5, -348.6],
        rotation: [-1.559, -0.029, 0.005],
        size: [0.9, 1.9, 0.3],
        description: 'Antenna Object 2',
      },
      {
        id: 3,
        position: [-0.52, 1.5, -346.1],
        rotation: [-1.559, -0.896, 0.005],
        size: [0.6, 2.4, 0.3],
        description: 'Antenna Object 3',
      },
      {
        id: 4,
        position: [-1.32, 1.05, -342.8],
        rotation: [-1.559, -1.474, 0.039],
        size: [0.6, 1.8, 0.3],
        description: 'Antenna Object 4',
      },
      {
        id: 5,
        position: [-1.52, 0.15, -342.8],
        rotation: [-1.559, -1.457, 0.039],
        size: [0.5, 0.9, 0.4],
        description: 'Antenna Object 5',
      },
      {
        id: 6,
        position: [-1.52, -0.05, -347.3],
        rotation: [-1.559, -1.78, 0.039],
        size: [0.6, 2.2, 0.4],
        description: 'Antenna Object 6',
      },
      {
        id: 7,
        position: [-1.22, -1.75, -346.1],
        rotation: [-1.644, -3, 0.039],
        size: [0.6, 2.2, 0.4],
        description: 'Antenna Object 7',
      },
      {
        id: 8,
        position: [-0.82, -1.95, -342.7],
        rotation: [-1.576, -2.885, 0.039],
        size: [0.6, 1.8, 0.4],
        description: 'Antenna Object 8',
      },
      {
        id: 9,
        position: [-0.02, -2.35, -342.9],
        rotation: [-1.576, -2.885, 0.039],
        size: [0.6, 0.9, 0.4],
        description: 'Antenna Object 9',
      },
      {
        id: 10,
        position: [0.08, -2.35, -339.5],
        rotation: [-1.576, -2.885, 0.039],
        size: [0.6, 1.5, 0.4],
        description: 'Antenna Object 10',
      },
      {
        id: 11,
        position: [-0.82, -1.95, -339.4],
        rotation: [-1.576, -2.868, 0.039],
        size: [0.6, 1.6, 0.4],
        description: 'Antenna Object 11',
      },
      {
        id: 12,
        position: [-0.22, -1.45, -348.6],
        rotation: [-1.576, -2.188, 0.056],
        size: [0.6, 1.5, 0.3],
        description: 'Antenna Object 12',
      },
      {
        id: 13,
        position: [-0.22, -1.45, -351],
        rotation: [-1.474, -2.086, 0.056],
        size: [0.5, 2.4, 0.3],
        description: 'Antenna Object 13',
      },
      {
        id: 14,
        position: [-0.72, -0.85, -351.2],
        rotation: [-2.052, -1.831, -0.437],
        size: [0.5, 1, 0.3],
        description: 'Antenna Object 14',
      },
      {
        id: 15,
        position: [0.58, -2.05, -347.3],
        rotation: [-1.627, 2.317, 0.022],
        size: [0.7, 2.2, 0.3],
        description: 'Antenna Object 15',
      },
      {
        id: 16,
        position: [1.38, -0.35, -348.7],
        rotation: [-1.627, 2.13, 0.022],
        size: [0.9, 2, 0.3],
        description: 'Antenna Object 16',
      },
      {
        id: 17,
        position: [1.28, -0.35, -351],
        rotation: [-1.627, 2.47, 0.022],
        size: [0.4, 2.2, 0.3],
        description: 'Antenna Object 17',
      },
      {
        id: 18,
        position: [2.08, -0.85, -346.2],
        rotation: [-1.406, 1.127, -0.199],
        size: [0.6, 2.2, 0.3],
        description: 'Antenna Object 18',
      },
      {
        id: 19,
        position: [-1.52, 0.2, -339.3],
        rotation: [-1.559, -1.457, 0.039],
        size: [0.5, 1.7, 0.4],
        description: 'Antenna Object 19',
      },
      {
        id: 20,
        position: [-1.42, 1.1, -339.3],
        rotation: [-1.559, -1.457, 0.039],
        size: [0.5, 1.7, 0.4],
        description: 'Antenna Object 20',
      },
      {
        id: 21,
        position: [1.38, 0.75, -347.3],
        rotation: [-1.542, 0.175, -0.046],
        size: [0.6, 2.3, 0.3],
        description: 'Antenna Object 21',
      },
      {
        id: 22,
        position: [1.98, 0.15, -342.6],
        rotation: [-1.576, 0.617, -0.063],
        size: [0.6, 2, 0.3],
        description: 'Antenna Object 22',
      },
      {
        id: 23,
        position: [1.38, 0.75, -342.9],
        rotation: [-1.542, 0.719, -0.182],
        size: [0.6, 1, 0.3],
        description: 'Antenna Object 23',
      },
      {
        id: 24,
        position: [1.98, 0.15, -339.4],
        rotation: [-1.576, 0.617, -0.063],
        size: [0.6, 1.6, 0.3],
        description: 'Antenna Object 24',
      },
      {
        id: 25,
        position: [1.38, 0.75, -339.4],
        rotation: [-1.576, 0.77, -0.063],
        size: [0.6, 1.6, 0.3],
        description: 'Antenna Object 25',
      },
      {
        id: 26,
        position: [0.98, -1.15, -351.2],
        rotation: [-1.627, 2.47, 0.022],
        size: [0.4, 1, 0.3],
        description: 'Antenna Object 26',
      },
    ],
  },
];
